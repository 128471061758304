exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-2-tsx": () => import("./../../../src/pages/index2.tsx" /* webpackChunkName: "component---src-pages-index-2-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-0-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test0.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-0-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-1-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test1.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-1-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-10-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test10.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-10-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-2-main-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test2/main.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-2-main-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-2-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test2.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-2-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-3-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test3.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-3-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-4-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test4.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-4-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-5-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test5.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-5-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-6-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test6.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-6-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-7-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test7.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-7-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-8-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test8.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-8-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-9-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test9.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-9-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/blog/posts/test.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-buildhome-repo-blog-posts-test-mdx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */)
}

